:root{
  --mainBlue:#2a2a72;
  --lightBlue:#009ffd;
  --mainWhite:#f3f3f3;
  --mainDark:#232528;
  --mainYellow:#ffa400; 
  --mainGreen:#006400;
}

body{
  font-family: 'Source Sans Pro', sans-serif !important;
  background: var(--mainWhite) !important;
  color: var(--mainDark) !important;
}
.text-title{
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}
.text-blue{
  color: var(--mainBlue);
}
.text-bright{
  color: var(--lightBlue);
}
.btn-black{
  background: transparent;
  text-transform: capitalize;
  font-size:1.2rem !important;
  font-weight: bold;
  color: var(--mainDark);
  border-radius: 0.5rem !important;
  border: 0.1rem solid var(--mainDark) !important;
}
.btn-black:hover{
  background: var(--mainDark) !important;
  color: var(--mainWhite) !important;
 }
.cart-icon{
  cursor: pointer;
  font-size: 1.2rem;
  color:var(--mainYellow);
}