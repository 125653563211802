body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root{
  --mainBlue:#2a2a72;
  --lightBlue:#009ffd;
  --mainWhite:#f3f3f3;
  --mainDark:#232528;
  --mainYellow:#ffa400; 
  --mainGreen:#006400;
}

body{
  font-family: 'Source Sans Pro', sans-serif !important;
  background: #f3f3f3 !important;
  background: var(--mainWhite) !important;
  color: #232528 !important;
  color: var(--mainDark) !important;
}
.text-title{
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}
.text-blue{
  color: #2a2a72;
  color: var(--mainBlue);
}
.text-bright{
  color: #009ffd;
  color: var(--lightBlue);
}
.btn-black{
  background: transparent;
  text-transform: capitalize;
  font-size:1.2rem !important;
  font-weight: bold;
  color: #232528;
  color: var(--mainDark);
  border-radius: 0.5rem !important;
  border: 0.1rem solid #232528 !important;
  border: 0.1rem solid var(--mainDark) !important;
}
.btn-black:hover{
  background: #232528 !important;
  background: var(--mainDark) !important;
  color: #f3f3f3 !important;
  color: var(--mainWhite) !important;
 }
.cart-icon{
  cursor: pointer;
  font-size: 1.2rem;
  color:#ffa400;
  color:var(--mainYellow);
}
